import {
  Button,
  Flex,
  IconButton,
  Text,
  TextArea,
  Tooltip,
  DropdownMenu,
} from "@radix-ui/themes";
import { useState } from "react";
import { HiMiniSparkles } from "react-icons/hi2";
import { IoMdArrowRoundBack } from "react-icons/io";
import { MdInfoOutline } from "react-icons/md";
import { fetchAIImage, generateImage } from "../../../lib/api";
import { addErrorAlert, aiImages } from "../../../lib/signals";
import { AIImageResource, ImageResource } from "../../../lib/types";
import { Spinner } from "react-bootstrap";
import SelectableImage from "./SelectableImage";
import UploadedImageGallery from "./UploadedImageGallery";
import { BsPlus, BsTrash } from "react-icons/bs";
import { PROMPT_STYLES } from "../../../lib/promptStyles";
import { CaretDownIcon } from "@radix-ui/react-icons";

const MAX_PROMPT_LENGTH = 120;

const PROMPT_DESCRIPTION = "Describe what you want to see in the image.";
const NEGATIVE_PROMPT_DESCRIPTION =
  "Describe what you don't want to see in the image.";

const FACE_IMAGE_DESCRIPTION =
  "Select an image of someone's face to generate a similar face in the AI image.";

const POSE_IMAGE_DESCRIPTION =
  "Select an image to use as a pose for the face image.";

interface AIImageGeneratorProps {
  onBackClick: () => void;
  unModifiableWidth?: number;
  unModifiableHeight?: number;
  onImageGenerated: (
    image: AIImageResource,
    width: number,
    height: number
  ) => void;
}

export default function AIImageGenerator(props: AIImageGeneratorProps) {
  const {
    onBackClick,
    unModifiableWidth,
    unModifiableHeight,
    onImageGenerated,
  } = props;

  const [prompt, setPrompt] = useState("");
  const [negativePrompt, setNegativePrompt] = useState("");
  const [width, setWidth] = useState(unModifiableWidth || 1024);
  const [height, setHeight] = useState(unModifiableHeight || 720);
  const [isGenerating, setIsGenerating] = useState(false);

  const [style, setStyle] = useState<string | null>(null);

  const [showFaceImageGallery, setShowFaceImageGallery] = useState(false);
  const [showPoseImageGallery, setShowPoseImageGallery] = useState(false);
  const [selectedImage, setSelectedImage] = useState<
    ImageResource | AIImageResource | null
  >(null);
  const [selectedPoseImage, setSelectedPoseImage] = useState<
    ImageResource | AIImageResource | null
  >(null);

  const onGenerate = async () => {
    if (!prompt) {
      addErrorAlert("Prompt is required.");
      return;
    }

    let finalPrompt = prompt;
    let finalNegativePrompt = negativePrompt;
    if (style) {
      const stylePromps = PROMPT_STYLES[style as keyof typeof PROMPT_STYLES];

      finalPrompt = stylePromps.prompt.replace("{prompt}", prompt);
      finalNegativePrompt = negativePrompt + " " + stylePromps.negative_prompt;
    }

    setIsGenerating(true);
    const response = await generateImage(
      finalPrompt,
      finalNegativePrompt,
      width,
      height,
      selectedImage?.url,
      selectedPoseImage?.url
    );

    if (!response.success) {
      setIsGenerating(false);
      addErrorAlert("Failed to generate image. Please try again.");
      return;
    }

    const imageId = response.data.id;

    let polling: NodeJS.Timeout;

    const fetchImage = async () => {
      const response = await fetchAIImage(imageId);

      if (!response.success) {
        addErrorAlert("Failed to generate image. Please try again.");
        clearInterval(polling);
        setIsGenerating(false);
        return;
      }

      const image = response.data as AIImageResource;

      if (image.status === "done") {
        clearInterval(polling);
        aiImages.value = [...aiImages.value, image];
        setIsGenerating(false);
        onImageGenerated(image, width, height);
        return;
      } else if (image.status === "failed") {
        clearInterval(polling);
        addErrorAlert("Failed to generate image. Please try again.");
        setIsGenerating(false);
        return;
      }
    };

    fetchImage();

    polling = setInterval(fetchImage, 1000);
  };

  return (
    <>
      {!showFaceImageGallery && !showPoseImageGallery && (
        <Flex direction="column">
          <Flex className="w-full">
            <IconButton onClick={onBackClick}>
              <IoMdArrowRoundBack />
            </IconButton>
          </Flex>
          <Flex className="w-full" direction="column">
            <Flex align="center">
              <label className="mr-2">Prompt</label>
              <Tooltip content={PROMPT_DESCRIPTION}>
                <IconButton variant="ghost">
                  <MdInfoOutline />
                </IconButton>
              </Tooltip>
            </Flex>
            <TextArea
              style={{ resize: "none" }}
              maxLength={MAX_PROMPT_LENGTH}
              placeholder="What do you want to see..."
              onChange={(e) => setPrompt(e.target.value)}
              value={prompt}
            />
          </Flex>
          <Flex className="w-full" direction="column">
            <Flex align="center">
              <label className="mr-2">Negative Prompt</label>
              <Tooltip content={NEGATIVE_PROMPT_DESCRIPTION}>
                <IconButton variant="ghost">
                  <MdInfoOutline />
                </IconButton>
              </Tooltip>
            </Flex>
            <TextArea
              style={{ resize: "none" }}
              maxLength={MAX_PROMPT_LENGTH}
              placeholder="What don't you want to see..."
              onChange={(e) => setNegativePrompt(e.target.value)}
              value={negativePrompt}
            />
          </Flex>
          <Flex direction="column" my="2">
            <DropdownMenu.Root>
              <DropdownMenu.Trigger className="w-1/3">
                <Button variant="outline">
                  {style || "Choose a style"}
                  <CaretDownIcon />
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                {Object.keys(PROMPT_STYLES).map((style) => {
                  return (
                    <DropdownMenu.Item
                      key={style}
                      onClick={() => setStyle(style)}
                    >
                      <Button variant="ghost">{style}</Button>
                    </DropdownMenu.Item>
                  );
                })}
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </Flex>
          <Flex className="w-full" direction="row" my="4">
            <div className="w-1/2">
              <Flex>
                <Flex className="mr-6" align="center">
                  <label className="mr-2">Face Image</label>
                  <Tooltip content={FACE_IMAGE_DESCRIPTION}>
                    <IconButton variant="ghost">
                      <MdInfoOutline />
                    </IconButton>
                  </Tooltip>
                </Flex>
                <Flex align="center">
                  {selectedImage && (
                    <IconButton variant="ghost">
                      <BsTrash onClick={() => setSelectedImage(null)} />
                    </IconButton>
                  )}
                </Flex>
              </Flex>
              {!selectedImage && (
                <Button
                  onClick={() => setShowFaceImageGallery(true)}
                  className="w-fit"
                >
                  Select an Image
                </Button>
              )}
              {selectedImage && (
                <Flex className="w-full" justify="start">
                  <SelectableImage
                    image={selectedImage}
                    handleSelect={() => {
                      setShowFaceImageGallery(true);
                    }}
                    width="10rem"
                  />
                </Flex>
              )}
            </div>
            <div>
              <Flex>
                <Flex align="center" className="mr-6">
                  <label className="mr-2">Pose Image</label>
                  <Tooltip content={POSE_IMAGE_DESCRIPTION}>
                    <IconButton variant="ghost">
                      <MdInfoOutline />
                    </IconButton>
                  </Tooltip>
                </Flex>
                <Flex align="center">
                  {selectedPoseImage && (
                    <IconButton variant="ghost">
                      <BsTrash onClick={() => setSelectedPoseImage(null)} />
                    </IconButton>
                  )}
                </Flex>
              </Flex>
              {!selectedPoseImage && (
                <Button
                  onClick={() => setShowPoseImageGallery(true)}
                  className="w-fit"
                >
                  Select an Image
                </Button>
              )}
              {selectedPoseImage && (
                <Flex className="w-full" justify="start">
                  <SelectableImage
                    image={selectedPoseImage}
                    handleSelect={() => {
                      setShowPoseImageGallery(true);
                    }}
                    width="10rem"
                  />
                </Flex>
              )}
            </div>
          </Flex>
          <Flex className="w-full" direction="column" my="4"></Flex>
          {!selectedImage && (
            <>
              {!unModifiableWidth && (
                <Flex className="w-full" direction="column">
                  <label>Width</label>
                  <input
                    type="number"
                    className="border-2 border-gray-200 rounded-md p-1"
                    value={width}
                    onChange={(e) => {
                      if (unModifiableWidth) {
                        return;
                      }
                      setWidth(parseInt(e.target.value));
                    }}
                    disabled={
                      isGenerating || !!unModifiableWidth || !!selectedImage
                    }
                  />
                </Flex>
              )}

              {!unModifiableHeight && (
                <Flex className="w-full" direction="column">
                  <label>Height</label>
                  <input
                    type="number"
                    className="border-2 border-gray-200 rounded-md p-1"
                    value={height}
                    onChange={(e) => {
                      if (unModifiableHeight) {
                        return;
                      }
                      setHeight(parseInt(e.target.value));
                    }}
                    disabled={isGenerating || !!unModifiableHeight}
                  />
                </Flex>
              )}
            </>
          )}
          <Flex className="w-full" my="2">
            <Button className="w-full p-4" onClick={onGenerate}>
              {!isGenerating ? (
                <>
                  <HiMiniSparkles />
                  <Text size="5">Generate</Text>
                  <HiMiniSparkles />
                </>
              ) : (
                <Spinner />
              )}
            </Button>
          </Flex>
        </Flex>
      )}
      {showFaceImageGallery && (
        <UploadedImageGallery
          onBackClick={() => setShowFaceImageGallery(false)}
          handleSelect={(image) => {
            setShowFaceImageGallery(false);
            setSelectedImage(image);
          }}
        />
      )}
      {showPoseImageGallery && (
        <UploadedImageGallery
          onBackClick={() => setShowPoseImageGallery(false)}
          handleSelect={(image) => {
            setShowPoseImageGallery(false);
            setSelectedPoseImage(image);
          }}
        />
      )}
    </>
  );
}
