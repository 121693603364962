import { Button, Flex, IconButton, Text } from "@radix-ui/themes";
import { PiRectangle, PiImageLight } from "react-icons/pi";
import { FaClone } from "react-icons/fa6";
import { FaPencilAlt, FaPlayCircle } from "react-icons/fa";
import { FaFaceLaugh } from "react-icons/fa6";
import { AIImageResource, Thumbnail } from "../../lib/types";
import {
  BLANK_BACKGROUND,
  BLANK_TEMPLATE,
  DEFAULT_IMAGE_OBJECT,
} from "../../lib/constants";
import { useState } from "react";
import Templates from "./Templates";
import { IoArrowBack } from "react-icons/io5";
import AIImageGenerator from "../edit/image/AIImageGenerator";
import { aiImages, editingThumbnailId, thumbnails } from "../../lib/signals";
import { BsPlay, BsPlayBtn } from "react-icons/bs";
import { createThumbnail } from "../../lib/api";

interface CreateThumbnailProps {
  selectedTab: "create" | "library";
  onTemplateSelect: (template: Thumbnail) => void;
}

export default function CreateThumbnail(props: CreateThumbnailProps) {
  const { selectedTab, onTemplateSelect } = props;

  const [showTemplates, setShowTemplates] = useState(false);
  const [showAIImageGenerator, setShowAIImageGenerator] = useState(false);
  const [generatedImages, setGeneratedImages] = useState<AIImageResource[]>([]);
  const [selectedImage, setSelectedImage] = useState<AIImageResource | null>();

  if (selectedTab !== "create") {
    return null;
  }

  return (
    <Flex align="center" direction="column">
      {!showTemplates && !showAIImageGenerator && (
        <>
          <Flex mt="4" align="center">
            <h1 className="logoText leading-tight tracking-tighter text-5xl drop-shadow-sm ">
              Choose how to get started
            </h1>
          </Flex>
          <Flex>
            <Flex
              direction="column"
              align="center"
              className="p-8 m-8 rounded-xl shadow-lg cursor-pointer hover:text-white hover:scale-110 hover:bg-brand-green transition duration-300 ease-in-out tracking-tighter"
              onClick={() => onTemplateSelect(BLANK_TEMPLATE)}
            >
              <h2>Create from Scratch</h2>
              <FaPencilAlt size="3rem" />
              <h5 className="my-4">Start from a blank slate</h5>
            </Flex>
            <Flex
              direction="column"
              align="center"
              className="p-8 m-8 rounded-xl shadow-lg cursor-pointer hover:text-white hover:scale-110 hover:bg-brand-green transition duration-300 ease-in-out tracking-tighter"
              onClick={() => setShowTemplates(true)}
            >
              <h2>Create from Template</h2>
              <FaClone size="3rem" />
              <h5 className="my-4">Use a pre-made template</h5>
            </Flex>
            <Flex
              direction="column"
              align="center"
              className="p-8 m-8 rounded-xl shadow-lg cursor-pointer hover:text-white hover:scale-110 hover:bg-brand-green transition duration-300 ease-in-out tracking-tighter"
              onClick={() => setShowAIImageGenerator(true)}
            >
              <h2>Generate AI FaceGen Image</h2>
              <FaFaceLaugh size="3rem" />
              <h5 className="my-4">
                Use AI to create a realistic image of yourself
              </h5>
            </Flex>
          </Flex>
        </>
      )}
      {showTemplates && (
        <>
          <Flex>
            <Flex align="center" justify="center">
              <IconButton onClick={() => setShowTemplates(false)} mx="2">
                <IoArrowBack size="3rem" />
              </IconButton>
              <h1 className="tracking-tighter m-0">Select a Template</h1>
            </Flex>
          </Flex>
          <Templates onTemplateSelect={onTemplateSelect} />
        </>
      )}
      {showAIImageGenerator && (
        <div className="w-full flex flex-col items-center">
          <div className="w-1/2">
            <h1 className="font-bold text-center">
              Generate AI Image with your face!
            </h1>
            <h2 className="text-2xl">
              Select an image of your face and optionally a pose image. Describe
              what you want the image to look like.
            </h2>
          </div>
          <div className="flex w-full">
            <div className="w-1/2 h-fit m-2 p-8 rounded-xl shadow-lg">
              <AIImageGenerator
                onBackClick={() => {
                  setShowAIImageGenerator(false);
                }}
                onImageGenerated={(image: AIImageResource) => {
                  aiImages.value = [...aiImages.value, image];
                  setGeneratedImages([...generatedImages, image]);
                  setSelectedImage(image);
                }}
                unModifiableWidth={1}
                unModifiableHeight={1}
              />
            </div>
            {generatedImages.length > 0 && (
              <div className="w-1/2 m-2 p-8 rounded-xl shadow-lg flex flex-col">
                <div className="mb-4 flex w-full overflow-scroll">
                  {generatedImages.map((image, index) => {
                    return (
                      <div
                        className="w-1/5 p-2 cursor-pointer flex justify-center hover:bg-gray-200"
                        key={index}
                        onClick={() => setSelectedImage(image)}
                      >
                        <img src={image.url} className="h-16" />
                      </div>
                    );
                  })}
                </div>
                <div>
                  {selectedImage && (
                    <img
                      src={selectedImage.url}
                      className="w-full h-auto object-contain"
                    />
                  )}
                </div>
                <div className="mt-4">
                  <Button
                    className="w-full p-4"
                    onClick={async () => {
                      if (!selectedImage) {
                        return;
                      }
                      const newThumbnail: Thumbnail = {
                        id: "",
                        background: BLANK_BACKGROUND,
                        assets: [
                          {
                            ...DEFAULT_IMAGE_OBJECT,
                            x: 50,
                            y: 50,
                            width: 700,
                            height: 700,
                            imageId: selectedImage.id,
                            src: selectedImage.url,
                          },
                        ],
                      };
                      await onTemplateSelect(newThumbnail);
                    }}
                  >
                    <Text size="5"> Create Thumbnail With Image</Text>
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Flex>
  );
}
