export const PROMPT_STYLES = {
  "(No style)": {
    prompt: "{prompt}",
    negative_prompt: "",
  },
  Watercolor: {
    prompt:
      "watercolor painting, {prompt}. vibrant, beautiful, painterly, detailed, textural, artistic",
    negative_prompt:
      "(lowres, low quality, worst quality:1.2), (text:1.2), watermark, anime, photorealistic, 35mm film, deformed, glitch, low contrast, noisy",
  },
  "Film Noir": {
    prompt:
      "film noir style, ink sketch|vector, {prompt} highly detailed, sharp focus, ultra sharpness, monochrome, high contrast, dramatic shadows, 1940s style, mysterious, cinematic",
    negative_prompt:
      "(lowres, low quality, worst quality:1.2), (text:1.2), watermark, (frame:1.2), deformed, ugly, deformed eyes, blur, out of focus, blurry, deformed cat, deformed, photo, anthropomorphic cat, monochrome, photo, pet collar, gun, weapon, blue, 3d, drones, drone, buildings in background, green",
  },
  Neon: {
    prompt:
      "masterpiece painting, buildings in the backdrop, kaleidoscope, lilac orange blue cream fuchsia bright vivid gradient colors, the scene is cinematic, {prompt}, emotional realism, double exposure, watercolor ink pencil, graded wash, color layering, magic realism, figurative painting, intricate motifs, organic tracery, polished",
    negative_prompt:
      "(lowres, low quality, worst quality:1.2), (text:1.2), watermark, (frame:1.2), deformed, ugly, deformed eyes, blur, out of focus, blurry, deformed cat, deformed, photo, anthropomorphic cat, monochrome, photo, pet collar, gun, weapon, blue, 3d, drones, drone, buildings in background, green",
  },
  Jungle: {
    prompt:
      'waist-up "{prompt} in a Jungle" by Syd Mead, tangerine cold color palette, muted colors, detailed, 8k,photo r3al,dripping paint,3d toon style,3d style,Movie Still',
    negative_prompt:
      "(lowres, low quality, worst quality:1.2), (text:1.2), watermark, (frame:1.2), deformed, ugly, deformed eyes, blur, out of focus, blurry, deformed cat, deformed, photo, anthropomorphic cat, monochrome, photo, pet collar, gun, weapon, blue, 3d, drones, drone, buildings in background, green",
  },
  Mars: {
    prompt:
      "{prompt}, Post-apocalyptic. Mars Colony, Scavengers roam the wastelands searching for valuable resources, rovers, bright morning sunlight shining, (detailed) (intricate) (8k) (HDR) (cinematic lighting) (sharp focus)",
    negative_prompt:
      "(lowres, low quality, worst quality:1.2), (text:1.2), watermark, (frame:1.2), deformed, ugly, deformed eyes, blur, out of focus, blurry, deformed cat, deformed, photo, anthropomorphic cat, monochrome, photo, pet collar, gun, weapon, blue, 3d, drones, drone, buildings in background, green",
  },
  "Vibrant Color": {
    prompt:
      "vibrant colorful, ink sketch|vector|2d colors, at nightfall, sharp focus, {prompt}, highly detailed, sharp focus, the clouds,colorful,ultra sharpness",
    negative_prompt:
      "(lowres, low quality, worst quality:1.2), (text:1.2), watermark, (frame:1.2), deformed, ugly, deformed eyes, blur, out of focus, blurry, deformed cat, deformed, photo, anthropomorphic cat, monochrome, photo, pet collar, gun, weapon, blue, 3d, drones, drone, buildings in background, green",
  },
  Snow: {
    prompt:
      "cinema 4d render, {prompt}, high contrast, vibrant and saturated, sico style, surrounded by magical glow,floating ice shards, snow crystals, cold, windy background, frozen natural landscape in background  cinematic atmosphere,highly detailed, sharp focus, intricate design, 3d, unreal engine, octane render, CG best quality, highres, photorealistic, dramatic lighting, artstation, concept art, cinematic, epic Steven Spielberg movie still, sharp focus, smoke, sparks, art by pascal blanche and greg rutkowski and repin, trending on artstation, hyperrealism painting, matte painting, 4k resolution",
    negative_prompt:
      "(lowres, low quality, worst quality:1.2), (text:1.2), watermark, (frame:1.2), deformed, ugly, deformed eyes, blur, out of focus, blurry, deformed cat, deformed, photo, anthropomorphic cat, monochrome, photo, pet collar, gun, weapon, blue, 3d, drones, drone, buildings in background, green",
  },
  "Line art": {
    prompt:
      "line art drawing {prompt} . professional, sleek, modern, minimalist, graphic, line art, vector graphics",
    negative_prompt:
      "anime, photorealistic, 35mm film, deformed, glitch, blurry, noisy, off-center, deformed, cross-eyed, closed eyes, bad anatomy, ugly, disfigured, mutated, realism, realistic, impressionism, expressionism, oil, acrylic",
  },
  YouTube: {
    prompt:
      "High-detail, high-resolution image of {prompt}, captured in a YouTube thumbnail style. Emphasize exaggerated facial expressions and dynamic body language. The face should display a shocked or surprised expression with wide-open mouth and eyes, conveying intense emotion. The upper body posture should complement the facial expression, adding to the overall dramatic impact. Focus on capturing the energy and expressiveness typical of attention-grabbing YouTube thumbnails, with a close-up view to highlight the details of the expression.",
    negative_prompt:
      "(lowres, low quality, worst quality:1.2), (text:1.2), watermark, (frame:1.2), subtle or muted expressions, lack of detail in facial features, static or unexpressive body language, backgrounds that distract from the subject, blurry or out of focus elements, overly realistic or subdued expressions, lack of emotional intensity, unnoticeable expressions, lack of clarity in facial details.",
  },
};
