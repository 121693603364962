import { v4 as uuidv4 } from "uuid";
import { Thumbnail } from "../types";
import { DEFAULT_IMAGE_SRC, EDITOR_WIDTH } from "../constants";

export default {
  id: uuidv4(),
  background: {
    type: "color",
    color:
      "linear-gradient(180deg, RGBA(209.9823,133.85076185567019,67.9677,1) 7%, rgba(53.97465149999999,36.295025149484566,20.995348500000002,1) 95%)",
  },
  assets: [
    {
      id: uuidv4(),
      type: "image",
      x: 30.9,
      y: 52.3,
      width: 768,
      height: 768,
      zIndex: 1,
      rotation: 0,
      src: DEFAULT_IMAGE_SRC,
      transparent: false,
      // dropShadow: { blur: 42, color: "rgb(0, 0, 0)", x: 0, y: 0 },
      imageOutline: { blur: 0, color: "white", width: 20 },
    },
    {
      id: uuidv4(),
      type: "text",
      x: 75.4,
      y: 26.4,
      width: 512,
      height: 144,
      zIndex: 2,
      rotation: 0,
      text: "#217",
      padding: 20,
      backgroundColor: "rgba(255, 255, 255, 0)",
      borderRadius: 15,
      border: { width: 0, style: "solid", color: "black" },
      longShadow: { width: 0, color: "black" },
      fontFamily: "Anton",
      fontWeight: 900,
      fontSize: 140,
      color: "white",
      textShadow: { x: 0, y: 0, blur: 33, color: "black" },
    },
    {
      id: uuidv4(),
      type: "text",
      x: 77.8,
      y: 52.9,
      width: 527,
      height: 109,
      zIndex: 3,
      rotation: 0,
      text: "Sample Text",
      padding: 20,
      backgroundColor:
        "rgba(247.99648619999996, 96.75836095428572, 10.981513800000037, 1)",
      borderRadius: 0,
      border: { width: 0, style: "solid", color: "black" },
      longShadow: { width: 0, color: "black" },
      fontFamily: "Anton",
      fontWeight: 400,
      fontSize: 100,
      color: "white",
      textShadow: { x: 0, y: 8, blur: 8, color: "black" },
    },
    {
      id: uuidv4(),
      type: "text",
      x: 68.9,
      y: 70,
      width: 755,
      height: 109,
      zIndex: 3,
      rotation: 0,
      text: "Lorem Ipsum Text",
      padding: 20,
      backgroundColor:
        "rgba(247.99648619999996, 96.75836095428572, 10.981513800000037, 1)",
      borderRadius: 0,
      border: { width: 0, style: "solid", color: "black" },
      longShadow: { width: 0, color: "black" },
      fontFamily: "Anton",
      fontWeight: 400,
      fontSize: 100,
      color: "white",
      textShadow: { x: 0, y: 8, blur: 8, color: "black" },
    },
  ],
} as Thumbnail;
